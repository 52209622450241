import './App.css';

import Footer from './Footer/Footer';
import Header from './Header/Header';

function App() {
  return (
    <div>
      <Header/>
      <div className="App">
        <header className="App-header">
          <p>
            stacksbunnies.club...
          </p>
        </header>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
