import './FourZeroFour.css';

function FourZeroFour() {
  return (
    <div id="main">
    	<div className="fof">
        <h1>Error 404</h1>
    	</div>
    </div>
  );
}

export default FourZeroFour;
