import {Route, Switch} from 'react-router-dom'

import App from './App'
import FourZeroFour from './FourZeroFour/FourZeroFour'
import React from 'react'

const Routes = () => {
    return (
        <Switch>
            <Route path='/' exact>
                <App />
            </Route>
            <Route path="*">
                <FourZeroFour />
            </Route>
        </Switch>
    )
}

export default Routes